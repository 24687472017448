@import 'abstracts';

input {
  outline: none !important; /* stylelint-disable-line declaration-no-important */
}

body {
  cap-input {
    div.p-inputgroup {
      input,
      input.p-inputtext,
      input.p-inputtext:hover,
      input.p-inputtext:enabled:hover:not(.p-state-error),
      input.p-inputtext:enabled:focus:not(.p-state-error) {
        font-size: 14px;

        &::placeholder {
          color: $title-text-color;
        }

        &.has-icon {
          padding-left: 36px;
        }
      }
    }
  }
}

/* stylelint-disable property-no-vendor-prefix */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  // Hide arrows for input type number
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}
