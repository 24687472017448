/* Base */
@import './base/reset';
@import './base/alignment';
@import './base/fonts';
@import './base/typography';

/* Vendor */
@import './vendor/bootstrap';
@import './vendor/prime';
@import './vendor/capturum-ui';
@import './vendor/font-awesone';

/* Components */
@import 'components/buttons';
@import 'components/dashboard-info-table';
@import 'components/login';
@import 'components/cpb-list-renderer';
@import 'components/cpb-form-renderer';
@import 'components/cards';
@import 'components/calendar';
@import 'components/chartjs';
@import 'components/confirm-dialogs';
@import 'components/dialogs';
@import 'components/dropdowns';
@import 'components/input';
@import 'components/tooltips';


html, body {
  overflow: hidden;
}
