@import 'abstracts';

div.back-btn.main {
  padding-left: 30px;
}

.p-button-label {
  transition: none;
}

.back-btn a {
  &:hover {
    color: var(--secondary-color);
  }
}

.button-wrapper {
  box-shadow: 0 2px 4px 0 rgba($black, 0.15);
  width: fit-content;
  height: fit-content;
}

cap-button {
  .p-button-label-icon-left:not(.p-button-icon-only) {
    .p-button-icon-left {
      padding: 0;
    }

    .p-button-label {
      padding-left: 12px;
    }
  }

  .filter-button {
    span.p-button-label {
      white-space: nowrap;
    }
  }

  &.gray button.p-button:not(:disabled) {
    border-color: transparent;
    background: $custom-button-background-color;
  }

  button.cap-button {
    text-decoration: none !important; /* stylelint-disable-line declaration-no-important */

    span {
      font-size: 14px;
      font-weight: $semi-bold-weight;
    }

    &.pill {
      border-radius: 32px !important; /* stylelint-disable-line declaration-no-important */
    }

    /* Handle hovering states (darker color) */
    &.primary:not(:disabled) {
      background-color: var(--cap-primary-color);
      border-color: var(--cap-primary-color);

      :hover, &:enabled:hover {
        background-color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
    }

    &.secondary:not(:disabled) {
      color: var(--cap-primary-color);
      border-color: var(--cap-primary-color);

      &:not(:disabled):hover, &:enabled:hover {
        color: var(--secondary-color);
        border-color: var(--secondary-color);
      }
    }

    &.p-button-text:not(:disabled),
    &.p-button-text:not(:disabled):hover {
      border: none;
      background-color: transparent;
    }

    &.success:not(:disabled) {
      background-color: $green-button-color;
      border-color: $green-button-color;

      &:not(:disabled):hover, &:enabled:hover {
        background-color: $green-button-color-darker;
        border-color: $green-button-color-darker;
      }
    }

    &.failure:not(:disabled) {
      background-color: $white;
      color: $red-button-color;
      border-color: $red-button-color;

      &:not(:disabled):hover, &:enabled:hover {
        color: $red-button-color-darker;
        border-color: $red-button-color-darker;
      }
    }
  }

  &[ng-reflect-disabled='true'] {
    pointer-events: none;
  }
}

button.hidden {
  display: none;
}
