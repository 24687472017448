@import 'abstracts';

$font-path: '../../assets/fonts/LGEIText';
$font-name: 'LGEITextTTF'; /* stylelint-disable-line value-keyword-case */

/* stylelint-disable */
@font-face {
  font-family: $font-name;
  font-weight: $regular-weight;
  font-style: normal;
  src:  url('#{$font-path}/#{$font-name}-Regular.ttf') format('truetype');
}

@font-face {
  font-family: $font-name;
  font-weight: 600;
  src: url('#{$font-path}/#{$font-name}-SemiBold.ttf') format('truetype');
}
