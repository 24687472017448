/* Font colors */
$text-color-emphasized: #333;
$text-color-understated: #777;

/* Font families */
$font-regular: LGEITextTTF regular; /* stylelint-disable-line value-keyword-case */

/* Font weights */
$hairline-weight: 100;
$thin-weight: 200;
$light-weight: 300;
$regular-weight: 400;
$medium-weight: 500;
$semi-bold-weight: 600;
$bold-weight: 700;
$extra-bold-weight: 800;
$black-weight: 900;

$filter-font-size: 0.9rem;
