@import 'abstracts';

cap-login {
  .login-wrapper {
    .content-wrapper {
      .content-left {
        padding: 70px;
      }
    }
  }

  .content-right .login-form .login-actions {
    .login-link {
      font-size: 13px;
      color: var(--cap-primary-color);

      .login-separator {
        color: $borders-color;
      }
    }
  }
}
