@import 'abstracts';

html, body {
  font-family: $font-regular;
  font-size: 16px;
}

a.link {
  cursor: pointer;
  font-weight: $bold-weight;
  font-size: 0.8rem;
}
