.p-dropdown-empty-message,
.p-multiselect-empty-message {
  padding: 10px 15px;
}

.p-dropdown-items-wrapper,
.p-scroller-viewport,
.p-scroller {
  height: auto;
  min-height: 150px;
  max-height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.p-dropdown-filter,
.p-multiselect-label {
  text-indent: 30px;
}

body .cap-dropdown-panel .p-dropdown-items p-dropdownitem[ng-reflect-disabled='true'] {
  cursor: not-allowed;
}

body .cap-dropdown-panel .p-dropdown-items .p-dropdown-item.p-disabled {
  background-color: $gray-light;
  color: $text-color-understated;
}
