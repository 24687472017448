/* Blueprint colors */
$primary: #ad0a34;
$primary-dark: #51001e;
$primary-darker: #9d0028;
$primary-light: #d64751;
$error-color: #ff3b30;

/* General colors */
$gray-light: #f9f9f9;
$gray-dark: #333;
$body-color: #5f6062;
$white: #fff;
$platinum: #e6e6e6;
$concrete: #f2f2f2;
$paginator-background: #e0e0e0;
$paginator-icon: #848484;

/* Named colors */
$ultramarine: #071a8c;
$alto: #ddd;
$liver: #4f5152;
$alabaster: #f9f9f9;
$half-opacity-mineshaft: rgba(51, 51, 51, 0.5);
$silver: #c0c0c0;
$gold: #d7be69;
$bronze: #a97142;

/* Project colors */
$divider-color: $concrete;
$title-text-color: #424242;
$primary-text-color: $gray-dark;
$understated-text-color: rgba($primary-text-color, 0.5);
$info-text-color: #3c3c3b;
$borders-color: $alto;
$table-text-color: #767679;

/* Disabled colors */
$disabled-color: $alto;
$input-disabled-background-color: $alabaster;

$external-page-background-color: #f4f4f4;
