@import 'abstracts';

cpb-form-renderer[formkey='form_distributor-detail'],
cpb-form-renderer[formkey='form_installer-detail'],
cpb-form-renderer[formkey='form_lead-detail'] {
  .form-renderer-actions {
    display: none;
  }
}

cpb-form-renderer.form_lead-detail-benelux-hp,
cpb-form-renderer.form_lead-detail-benelux-ac,
cpb-form-renderer.form_lead-detail-it-hp,
cpb-form-renderer.form_lead-detail-it-ac,
cpb-form-renderer.form_lead-detail-es-ac,
cpb-form-renderer.form_lead-detail-es-hp,
cpb-form-renderer.form_lead-detail-uk-ac,
cpb-form-renderer.form_lead-detail-uk-hp,
cpb-form-renderer.form_lead-detail-de-ac,
cpb-form-renderer.form_lead-detail-de-hp,
cpb-form-renderer.form_lead-detail-pl-ac,
cpb-form-renderer.form_lead-detail-pl-hp,
cpb-form-renderer.form_lead-detail-gr-ac,
cpb-form-renderer.form_lead-detail-gr-hp, {
  padding-top: 0;

  .form-renderer-actions {
    display: none;
  }
}

cpb-form-renderer {
  box-shadow: 0 1px 2px 0 rgba(66, 66, 66, 0.15);

  formly-form {
    padding: 0 10px;
  }

  cpb-textarea-input {
    cpb-readonly-container {
      div {
          white-space: pre-wrap;
      }
    }
  }

  cpb-form-renderer-header {
    .form-renderer-header {
      padding: 13px 0 11px;
      margin: 0 10px 15px;

      .form-renderer-title {
        font-size: 14px;
        font-weight: $bold-weight;
        color: $title-text-color;
      }
    }
  }

  cpb-content-wrapper {
    label {
      color: $gray-dark;
    }
  }

  .readonly-container {
    border: 1px solid $borders-color;
    border-radius: 3px;
    background-color: $input-disabled-background-color;
    padding-left: 11px;
    font-size: 14px;
  }

  cpb-number-format-input {
    cap-input-number {
      .cap-number-format-readonly {
        border-radius: 3px;
        background-color: $input-disabled-background-color;
      }

      input {
        font-size: 14px !important;
        padding-left: 11px !important;
      }
    }
  }
}
