.card {
  border: 0;
  border-radius: 3px;
  padding: 10px;
}

app-card-layout {
  .cpb-list-renderer {
    padding: 0;

    .table-filters.cap-table-filter {
      margin-top: 20px;
      padding-bottom: 0;
    }
  }
}
