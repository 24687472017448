@import 'abstracts';

body p-dialog,
body p-dynamicdialog {
  &.timeline-dialog .p-dialog {
    width: 486px;
    right: 0;
    height: 100vh;
    max-height: unset;
    margin: 0;
  }

  .p-dialog.drop-lead, .p-dialog.product-picker {
    min-width: 870px;
  }

  .p-dialog:not(.p-confirm-dialog) {
    display: flex;
    flex-direction: column;

    .p-dialog-header {
      background: linear-gradient(-90deg, var(--cap-primary-color) 0%, var(--cap-primary-color) 100%);
      height: 74px;
      color: white;
      font-size: 20px;
      font-family: $font-regular;
      font-weight: bold;
      padding: 0 33px;
      border-radius: 6px 6px 0 0;

      .p-dialog-header-close-icon {
        color: white;
        font-size: 21px;
      }
    }

    .content {
      padding-bottom: 30px;
      border-bottom: 1px solid $borders-color;

      span {
        color: $info-text-color;
        font-size: 13px;
        display: flex;

        &.drop-lead-info {
          margin-bottom: 25px;
        }
      }
    }

    .footer {
      padding: 25px 0;
      display: flex;
      justify-content: space-between;
    }

    .p-dialog-content {
      height: 100%;
      padding: 16px 32px;
      border-radius: 0 0 6px 6px;
    }

    .p-dialog-footer {
      padding: 0 32px 28px;
      border: none;

      .actions {
        padding-top: 28px;
        border-top: solid 1px $border-color;
      }
    }
  }
}
