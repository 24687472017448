@import 'variables';

@mixin setRightGutters($value) {
  & > * {
    margin-right: $value;

    &:last-child {
      margin-right: 0;
    }
  }
}

@mixin blocktify() {
  display: block;
  width: 100%;
}

@mixin setBottomGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-bottom: $value;

    &:last-child {
      margin-bottom: $lastGutter;
    }
  }
}

@mixin setTopGutters($value, $lastGutter: 0, $start: 1n, $iteration: 1) {
  & > *:nth-child(#{$start} + #{$iteration}) {
    margin-top: $value;

    &:last-child {
      margin-top: $lastGutter;
    }
  }
}

@mixin setBottomMargin($value) {
  margin-bottom: $value;

  &:last-child {
    margin-bottom: 0;
  }
}

@mixin setLeftGutters($value) {
  & > * {
    margin-left: $value;

    &:first-child {
      margin-left: 0;
    }
  }
}

@mixin proportions($size) {
  width: $size;
  height: $size;
}

@mixin setObjectFit($type) {
  width: 100%;
  height: 100%;
  object-fit: $type;
}

@mixin setAbsoluteCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  left: $size;
  transform: translate(-$size, -$size);
}

@mixin setAbsoluteVerticalCenter() {
  $size: 50%;
  position: absolute;
  top: $size;
  transform: translateY(-$size);
}

@mixin setAbsoluteHorizontalCenter() {
  $size: 50%;
  position: absolute;
  left: $size;
  transform: translateX(-$size);
}

@mixin setFontAwesomeFreeIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Free';
  font-weight: $weight;
  content: $code;
}

@mixin setFontAwesomeProIcon($code, $weight: 900) {
  font-family: 'Font Awesome 5 Pro';
  font-weight: $weight;
  content: $code;
}

@mixin straightTop() {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
}

@mixin straightBottom() {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

@mixin sectionDivider($vertical-margins, $color) {
  margin: $vertical-margins 0;
  padding: $vertical-margins 0;
  border-bottom: 1px solid $color;

  &:last-of-type {
    border: none;
    margin: 0;
  }
}

@mixin card-layout($padding) {
  padding: $padding;
  background-color: $white;
  border-radius: 5px;
}

@mixin flexCentering() {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin preventTextBreakout() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
