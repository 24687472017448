@import 'abstracts';

app-table-widget-layout {
  p-paginator {
    display: none;
  }

  tbody.p-datatable-tbody {
    tr.cap-info-table__row {
      box-shadow: 0px 1px 2px 0px rgba(66, 66, 66, 0.15);

      td.cap-info-table__column {
        height: 35px;
        
        cap-cell-data {
          color: $table-text-color;
        }

        &:first-child {
          border: 1px solid white;
          border-right: none;
        }

        &:last-child {
          border: 1px solid white;
          border-left: none;
        }
      }
    }
  }

  .card-layout {
    height: $dashboard-widget-height;
    overflow: hidden;

    app-card-layout {
      flex: 1;
  
      .card-layout {
        height: 100%;
        
        div.header {
          padding-bottom: 10px;
        }
      }
    }
  }
}

app-installer-status {
  height: $dashboard-widget-height;
  display: flex;
  flex-direction: column;

  app-card-layout {
    flex: 1;

    .card-layout {
      height: 100%;
    }
  }
}