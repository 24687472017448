body .p-tooltip {
  .p-tooltip-text {
    background: $white;
    color: rgba($text-color-emphasized, 0.5);
    border-radius: 5px;
    padding: 10px;
  }

  &.p-tooltip-top .p-tooltip-arrow, &.p-tooltip-bottom .p-tooltip-arrow {
    border-top-color: $white;
    border-bottom-color: $white;
  }
}
